import React, { useState, useEffect } from 'react';
import './footer.css';
import logo from '../../assets/images/logo.svg';
import logoDark from '../../assets/images/logoDark.svg';
import socialIcons from "../../assets/images/socialIcons/socialIcons.js";
;

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [yearsInBusiness, setYearsInBusiness] = useState(0);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    const calculateYears = () => {
      const startDate = new Date('1993-01-02');
      const currentDate = new Date();
      const diffInYears = currentDate.getFullYear() - startDate.getFullYear();
      setYearsInBusiness(diffInYears);
    };

    calculateYears();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getFontSize = (baseSize) => {
    if (windowWidth > 3840) return baseSize * 3.1;
    if (windowWidth > 3200) return baseSize * 2.8;
    if (windowWidth > 2880) return baseSize * 2;
    if (windowWidth > 2560) return baseSize * 1.9;
    if (windowWidth > 2240) return baseSize * 1.8;
    if (windowWidth > 1920) return baseSize * 1.6;
    if (windowWidth > 1728) return baseSize * 1.3;
    if (windowWidth > 1536) return baseSize * 1.2;
    if (windowWidth > 1440) return baseSize * 0.95;
    if (windowWidth > 1280) return baseSize * 0.9;
    if (windowWidth > 1152) return baseSize * 0.85;
    if (windowWidth > 1024) return baseSize * 0.8;
    if (windowWidth > 896) return baseSize * 0.75;
    if (windowWidth > 768) return baseSize * 0.7;
    if (windowWidth > 640) return baseSize * 0.65;
    if (windowWidth > 480) return baseSize * 0.6;
    if (windowWidth > 320) return baseSize * 0.55;
    if (windowWidth > 240) return baseSize * 0.5;
    if (windowWidth > 160) return baseSize * 0.45;
    return baseSize * 0.4; 
  };

  return (
    <>
    <div className='bg-[#1E1E1E] pt-20 pl-48 pr-32 pb-[25vh]  relative z-0'>
    <div className="parentFooter bg-[#1E1E1E] text-white  relative space-y-[5vh] ">
      <div className="div1footer">
        <img src={logoDark} alt="Logo" style={{ height: `${getFontSize(7)}rem` }} />
      </div>
      
      <div className="div2footer flex flex-col ">
        <h3 className="font-bold" style={{ fontSize: `${getFontSize(1.25)}rem` }}>TELEFON</h3>
        <p style={{ fontSize: `${getFontSize(1)}rem` }}>+48 14 679 30 50</p>
        <p style={{ fontSize: `${getFontSize(1)}rem` }}>+48 14 679 23 25</p>
      </div>
      
      <div className="div3footer flex flex-col">
        <h3 className="font-bold" style={{ fontSize: `${getFontSize(1.25)}rem` }}>E-MAIL</h3>
        <p style={{ fontSize: `${getFontSize(1)}rem` }}>tawkor@tawkor.pl</p>
      </div>
      
      <div className="div4footer font-medium" style={{ fontSize: `${getFontSize(1)}rem` }}>
        <p>Od {yearsInBusiness} lat służymy pomocą klientom< br />z Polski jak i zagranicy.</p>
        <p>W czym moglibyśmy pomóc <span className='text-[#ED1C24]'>Tobie</span>? </p>
      </div>
      
      <div className="div5footer flex flex-col">
        <h3 className="font-bold" style={{ fontSize: `${getFontSize(1.25)}rem` }}>GODZINY OTWARCIA</h3>
        <p style={{ fontSize: `${getFontSize(1)}rem` }}>pon-pt: 8:00 - 18:00</p>
        <p style={{ fontSize: `${getFontSize(1)}rem` }}>sob: 9:00 - 13:00</p>
      </div>
      
      <div className="div6footer flex flex-col">
        <h3 className="font-bold" style={{ fontSize: `${getFontSize(1.25)}rem` }}>ADRES</h3>
        <p style={{ fontSize: `${getFontSize(1)}rem` }}>Wola Rzędzińska 432B</p>
        <p style={{ fontSize: `${getFontSize(1)}rem` }}>33-150 Wola Rzędzińska</p>
      </div>
      
      <div className="div7footer flex justify-start items-center space-x-4 relative z-40">
        <a
          href="https://www.instagram.com/taw_kor/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Odwiedź nasz profil na Instagramie"
          className="relative group"
        >
          <img src={socialIcons["Instagram.svg"]} alt="" className="invert transition-opacity duration-300 group-hover:opacity-0" style={{ height: `${getFontSize(6)}rem` }} />
          <img
            src={socialIcons["HoverInstagramDark.svg"]}
            alt=""
            className="absolute top-0 left-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            style={{ height: `${getFontSize(6)}rem` }}
          />
        </a>
        <a
          href="https://www.facebook.com/TawkorAutoCzesci/?locale=pl_PL"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Odwiedź naszą stronę na Facebooku"
          className="relative group"
        >
          <img src={socialIcons["Facebook.svg"]} alt="" className="invert transition-opacity duration-300 group-hover:opacity-0" style={{ height: `${getFontSize(6)}rem` }} />
          <img
            src={socialIcons["HoverFacebookDark.svg"]}
            alt=""
            className="absolute top-0 left-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            style={{ height: `${getFontSize(6)}rem` }}
          />
        </a>
        <a
          href="https://www.tiktok.com/@tawkor"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Odwiedź nasz profil na TikToku"
          className="relative group"
        >
          <img src={socialIcons["TikTok.svg"]} alt="" className="invert transition-opacity duration-300 group-hover:opacity-0" style={{ height: `${getFontSize(6)}rem` }} />
          <img
            src={socialIcons["HoverTikTokDark.svg"]}
            alt=""
            className="absolute top-0 left-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            style={{ height: `${getFontSize(6)}rem` }}
          />
        </a>
        <a
          href="https://x.com/KorTaw61700"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Odwiedź nasz profil na X (dawniej Twitter)"
          className="relative group"
        >
          <img src={socialIcons["X.svg"]} alt="" className="invert transition-opacity duration-300 group-hover:opacity-0" style={{ height: `${getFontSize(6)}rem` }} />
          <img
            src={socialIcons["HoverXDark.svg"]}
            alt=""
            className="absolute top-0 left-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            style={{ height: `${getFontSize(6)}rem` }}
          />
        </a>
      </div>
    </div>

    <div 
        className="gradient-sectionFooter absolute bottom-0 left-0 h-[40vh]  z-10"
       
      ></div>
    </div>
    </>
  );
};

export default Footer;